<template>
  <main class="default-container tour-places page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <div class="banner weight-700">
      פרסומות לקטגוריה בסביבה   
    </div>
    <h5 class="text-center mb-5 mt-10 fs23">הקוליסאום</h5>
    <div class="main-content mt-15">
      <div class="content-block">
        <p class="mb-7">
          <b>{{ $t("Local Name") }}: </b>
          קולוסיאו
        </p>
        <p class="mb-10">
          <b>{{ $t("Location") }}: </b>
          Rione XIV Celio, רומא, איטליה
        </p>
        <p class="mb-10"> 
          האמפיתיאטרון הגדול ביותר של האימפריה הרומית הוא חובה לראות ברומא. פעם קולוסיאום אירח קרבות גלדיאטורים, קרבות בעלי חיים ואפילו קרבות מים. הבניין היה מתנה מהקיסר אספסיאנוס לעמו ו (שלא כמו היום) הכניסה הייתה חופשית. לכן המשחקים והקרבות היו פופולריים מאוד. נבנה ושונה תחת שלטון שושלת פלביה, הקולוסיאום נותר מורשת חשובה של התרבות הרומית העתיקה.
        </p>
        <p class="mb-10"> 
          לא רק שיש הרבה סרטים שמציגים את הקולוסיאום (למשל חג רומי או אל רומא באהבה), זה אפילו אחד המקומות החשובים בזכיינית המשחק המפורסמת - Assassin's Creed.
        </p>
        <p class="mb-10">
          בביקור בקולוסיאום היו מוכנים לקהל התיירים ולתורי כרטיסים ארוכים, לרוב יותר משעה. כדי להימנע לחלוטין מהתורים תוכלו לקנות כרטיסים באינטרנט או לקנות אותם בכניסה לפורום הרומי (שנמצא קרוב מאוד לקולוסיאום) שם התורים קצרים בהרבה. הכרטיסים הרגילים יביאו אתכם לחלקים החשובים ביותר במתחם אך אם תרצו להגיע רחוק מזה (למשל המחתרת) תצטרכו לקנות את כרטיסי הסיור המודרך.
        </p>
        <p class="mb-20">
          בהיותו במרכז העיר, הקולוסיאום נגיש היטב באמצעות התחבורה הציבורית - מטרו (קו B - תחנה: קולוסיאו), אוטובוס (קווים 75, 81, 673, 175, 204) או חשמלית (קו 3).
        </p>
        <p class="weight-700 fs20 mb-7">
          {{ $t("More information and contact") }}
        </p>
        <p class="mb-10">
          <span>{{ $t("Wikipedia") }}</span><br>
          https://en.wikipedia.org/wiki/Colosseum
        </p>
        <p class="mb-10">
          <span>{{ $t("Official site") }}</span><br>
          http://www.coopculture.it/en/colosseo-e-shop.cfm
        </p>
        <p class="mb-10">
          <span>{{ $t("Phone") }}</span><br>
          +39 06 3996 7700
        </p>
        <p class="mb-10">
          <span>{{ $t("Address") }}</span><br>
          פיאצה דל קולוסיאו, 1 00184 רומא, איטליה
        </p>
        <p class="mb-10">
          <span>{{ $t("Coordinates") }}</span><br>
          41 ° 53'24.987 "N 12 ° 29'32.479" E
        </p>
      </div>
      <div class="content-block">
        <img src="/assets/img/tour-places/church.png" alt="" class="place-img">
        <p class="weight-700 fs20 mb-7 mt-10">
          {{ $t("Entrance Fee") }}
        </p>
        <p>
          <b>{{ $t("Adults") }}: </b>
          16 אירו + 2 אירו
        </p>
        <p>
          <b>*{{ $t("Reduced") }}: </b>
          € 2 + € 2 * (תלמידי האיחוד האירופי בגילאי 18-25, מורים)
        </p>
        <p>
          <b>{{ $t("Children (under 18 years old)") }}: </b>
          {{ $t("Free") }}
        </p>
        <p class="mb-10">
          דמי הזמנה לכרטיס מקוון *
        </p>
        <p class="mb-10">
          הכרטיס המשולב בן יומיים תקף גם לביקור בגבעת פאלטין ובפורום רומנום. 
        </p>
        <p class="mb-10">
          למידע נוסף אודות אפשרויות לוח הזמנים ואפשרויות השפה, עיין באתר הרשמי.
        </p>
      </div>
    </div>
    <div class="map-block mt-20">
      <img src="/assets/img/map.png" alt="">
    </div>

    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "TourPlacesDetail",
  components: {
    Loading
  },
  computed: {
    ...mapState({
      loading: (state) => state.deal.loading
    })
  }
};
</script>

<style lang="scss" scoped>
.tour-places {
  .banner {
    background-color: #ffffff;
    text-align: center;
    font-size: 70px;
  }
  .main-content {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    .content-block {
      width: 50%;
      margin: 0 7px;
      p {
        font-size: 18px;
      }
      .place-img {
        width: 100%;
        border-radius: 10px;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .map-block {
    img {
      width: 100%;
    }
  }
}
.ltr-type {
  .read-more {
    text-align: left;
  }
}
</style>
